
const CLIENTID = process.env.VUE_APP_GOOGLE_CLIENT_ID !== undefined && process.env.VUE_APP_GOOGLE_CLIENT_ID !== "" ? process.env.VUE_APP_GOOGLE_CLIENT_ID : "{{ VUE_APP_GOOGLE_CLIENT_ID }}";
const REDIRECT = process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT_URL !== undefined && process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT_URL !== "" ? process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT_URL : "{{ VUE_APP_GOOGLE_OAUTH_REDIRECT_URL }}";

export default {
	install: function (Vue) {
		let service = {};
		Vue.mixin({
			mounted() {
				/**
				 * Crea i parametri per la lista
				 * @param page
				 * @param sort
				 * @param search
				 * @param search_fields
				 * @returns {string}
				 */
				const buildQueryParams = (params) => {
					let paramData = [];
					if (params) {
						Object.keys(params).map((key) => {
							paramData.push(
								`${key}=${encodeURIComponent(params[key])}`
							);
						});
						return paramData.length ? params.join("&") : ``;
					}
					return ``;
				};

				/**
				 * LOGIN
				 */
				let login = {};
				login.login = (email, psw) => {
					return this.$api.post(`/login`, {
						email: email,
						password: psw,
					});
				};
				login.forgotPassword = (email) => {
					return this.$api.post(`/forgot-password`, { email: email });
				};
				login.resetPassword = (data) => {
					return this.$api.post("/reset-password", data);
				};
				login.checkMail = (email) => {
					return this.$api.post("/check-email", { email: email });
				};
				login.register = (user) => {
					return this.$api.post("/host/register", user);
				};
				login.logout = () => {
					return this.$api.post("/logout");
				};

				login.facebook = (token) => {
					return this.$api.post(`/login-with/facebook`, {
						token: token,
					});
				};
				login.googleLogin = (token) => {
					return this.$api.post('/login-with/google', token);
				}

				login.google = (from) => {
					const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;
					const options = {
						redirect_uri: REDIRECT,
						client_id: CLIENTID,
						response_type: "token",
						prompt: "consent",
						scope: [
							"https://www.googleapis.com/auth/userinfo.profile",
							"https://www.googleapis.com/auth/userinfo.email",
						].join(" "),
						state: from,
					};
					const qs = new URLSearchParams(options);
					return `${rootUrl}?${qs.toString()}`;
				};
				login.apple = (token) => {
					return this.$api.post('/login-with/apple', { token: token });
				}

				service.login = login;

				/**
				 * USER
				 */
				let user = {};
				user.me = {};
				user.phone = {};
				user.email = {};
				user.me.get = () => {
					return this.$api.get("/me");
				};
				user.me.update = (user) => {
					return this.$api.post("/host/me", user);
				};
				user.avatar = (file) => {
					return this.$api.post("/avatar", file);
				};
				user.phone.verification = () => {
					return this.$api.post("/phone/verification-notification");
				};
				user.phone.opt = (code) => {
					return this.$api.post("/verify-phone", { code: code });
				};
				user.email.verification = () => {
					return this.$api.post("/email/verification-notification");
				};
				user.email.verify = (id, hash, query) => {
					return this.$api.get(`/verify-email/${id}/${hash}${query}`)
				}
				user.documents = () => {
					return this.$api.get("/documents");
				};
				user.updatePassword = (data) => {
					return this.$api.post("/update-password", data);
				};
				user.delete = () => {
					return this.$api.post('/remove-account');
				}

				service.user = user;

				/**
				 * DOCUMENTS
				 */
				let docs = {};
				docs.main = {};
				docs.detail = {};
				docs.main.get = () => {
					return this.$api.get("/documents");
				};
				docs.main.post = (doc) => {
					return this.$api.post("/documents", doc);
				};
				docs.detail.get = (docid) => {
					return this.$api.get(`/documents/${docid}`);
				};
				docs.detail.put = (docid, doc) => {
					return this.$api.put(`/documents/${docid}`, doc);
				};
				service.docs = docs;

				/**
				 * MEDIA
				 */
				let media = {};
				media.main = {};
				media.main.get = () => {
					return this.$api.get("/media");
				};
				media.main.post = (file, headers) => {
					return this.$api.postMedia("/media", file, headers);
				};
				service.media = media;

				/**
				 * BOOKINGS
				 */
				let bookings = {};
				bookings.detail = {};
				bookings.get = (filters) => {
					let filtri = filters ? `?${filters}` : "";
					return this.$api.get(`/host/bookings${filtri}`);
				};
				bookings.reasons = () => {
					return this.$api.get("/host/reasons");
				};
				bookings.detail.get = (bid) => {
					return this.$api.get(`/host/bookings/${bid}`);
				};
				bookings.detail.cancel = (bid, reason) => {
					return this.$api.post(`/host/bookings/${bid}/cancel`);
				};
				bookings.statuses = () => {
					return this.$api.get('/enums/booking-status');
				}
				service.bookings = bookings;

				/**
				 * GARAGES
				 */
				let garages = {};
				garages.main = {};
				garages.detail = {};
				garages.media = {};
				garages.media.main = {};
				garages.media.detail = {};
				garages.enum = {};
				garages.main.get = () => {
					return this.$api.get("/host/garages");
				};
				garages.main.post = (garage) => {
					return this.$api.post("/host/garages", garage);
				};
				garages.detail.get = (gid) => {
					return this.$api.get(`/host/garages/${gid}`);
				};
				garages.detail.put = (gid, garage) => {
					return this.$api.put(`/host/garages/${gid}`, garage);
				};
				garages.detail.delete = (gid) => {
					return this.$api.delete(`/host/garages/${gid}`);
				};
				garages.media.main.get = () => {
					return this.$api.get("/host/garage-media");
				};
				garages.media.main.post = (file, headers) => {
					return this.$api.post("/host/garage-media", file, headers);
				};
				garages.media.detail.get = (gid) => {
					return this.$api.get(`/host/garage-media/${gid}`);
				};
				garages.media.detail.put = (gid, garage) => {
					return this.$api.put(`/host/garage-media/${gid}`, garage);
				};
				garages.media.detail.delete = (pid) => {
					return this.$api.delete(`/host/garage-media/${pid}`);
				};
				garages.reviews = (filters) => {
					let filtri = filters ? `?${filters}` : "";
					return this.$api.get(`/host/reviews${filtri}`);
				};
				garages.enum.gates = () => {
					return this.$api.get(`/enums/gate-types`);
				}
				garages.enum.garages = () => {
					return this.$api.get(`/enums/garage-type`)
				}
				service.garages = garages;

				/**
				 * VEHICLES
				 */
				let vehicles = {};
				vehicles.get = () => {
					return this.$api.get("/vehicle_types");
				};
				service.vehicles = vehicles;

				/**
				 * AVAILABILITY
				 */
				let availability = {};
				availability.detail = {};
				availability.get = (gid, start) => {
					let startNow = start ? `&filters[end_at]=${start}` : '';

					return this.$api.get(
						`/host/availabilities?filters[garage_id]=${gid}${startNow}`
					);
				};
				availability.post = (item) => {
					return this.$api.post("/host/availabilities", item);
				};
				availability.detail.get = (aid) => {
					return this.$api.get(`/host/availabilities/${aid}`);
				};
				availability.detail.put = (aid, item) => {
					return this.$api.put(`/host/availabilities/${aid}`, item);
				};
				availability.detail.delete = (aid) => {
					return this.$api.delete(`/host/availabilities/${aid}`);
				};
				availability.multiDelete = (ids) => {
					return this.$api.post(`/host/availabilities/multiple-delete`, { ids: ids })
				}
				availability.multiple = (items) => {
					return this.$api.post('/host/availabilities/multiple', { data: items });
				}
				service.availability = availability;

				/**
				 * STATS
				 */
				let stats = {};
				stats.earnings = (filters) => {
					let filtri = filters ? `?${filters}` : "";
					return this.$api.get(`/host/stats/earnings${filtri}`);
				};
				stats.bookings = (filters) => {
					let filtri = filters ? `?${filters}` : "";
					return this.$api.get(`/host/stats/bookings${filtri}`);
				};
				stats.totals = () => {
					return this.$api.get("/host/stats/totals");
				};
				service.stats = stats;

				/**
				 * NOTIFICATIONS
				 */
				let notifications = {};
				notifications.get = () => {
					return this.$api.get('/notifications');
				}
				notifications.getDetail = (id) => {
					return this.$api.get(`/notifications/${id}`);
				}
				notifications.read = (id) => {
					return this.$api.post(`/notifications/${id}/read`);
				}

				notifications.fcm = (token) => {
					return this.$api.post(`/fcm-token`, { token: token })
				}

				service.notifications = notifications;

				/**
				 * STRIPE
				 */
				let stripe = {};
				stripe.connect = () => {
					return this.$api.post('/stripe/connect');
				}
				service.stripe = stripe;


				/**
				 * QR CODE
				 */
				let qr = {};
				qr.get = (id) => {
					return this.$api.get(`/qr/${id}`);
				}
				service.qr = qr;
			},
		});
		Vue.prototype.$service = service;
	},
};
