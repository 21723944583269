<template>
	<div class="accountedit">
		<div class="accountedit__head">
			<span class="accountedit__head__back" @click="closeDrawer">
				<span class="accountedit__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="accountedit__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
		</div>
		<div class="accountedit__box">
			<div class="accountedit__box__head">
				<div class="accountedit__box__head__title">
					{{ $t('account.edit.title') }}
				</div>
				<span class="accountedit__box__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div v-if="isLoading" class="accountedit__loading">
				<template v-if="!editSaved">
					<span class="accountedit__loading__spinner">
						<span class="accountedit__loading__spinner__box">
							<Spinner />
						</span>
						<span class="accountedit__loading__spinner__label">
							{{ $t('common.is_saving') }}
						</span>
					</span>
				</template>
				<template v-else>
					<span class="accountedit__loading__success">
						{{ $t('common.edit_saved') }}
					</span>
				</template>
			</div>
			<form id="accountedit" class="accountform">
				<div class="form__field">
					<label for="firstname" class="accountedit__label">
						{{ $t('account.edit.firstname.label') }}
					</label>
					<input type="firstname" name="firstname" id="firstname" class="form__input" :placeholder="$t('account.edit.firstname.placeholder')" v-model="form.name" />
					<small v-if="errors && errors.name" class="form__error" v-html="errors.name"></small>
				</div>
				<div class="form__field">
					<label for="lastname" class="accountedit__label">
						{{ $t('account.edit.lastname.label') }}
					</label>
					<input type="lastname" name="lastname" id="lastname" class="form__input" :placeholder="$t('account.edit.lastname.placeholder')" v-model="form.surname" />
					<small v-if="errors && errors.surname" class="form__error" v-html="errors.surname"></small>
				</div>
				<div class="form__field email">
					<label for="email" class="accountedit__label">
						{{ $t('account.edit.email.label') }}
					</label>
					<input type="email" name="email" id="email" class="form__input" :value="form.email" readonly />
					<small v-if="errors && errors.email" class="form__error" v-html="errors.email"></small>
				</div>
				<div class="form__field phone">
					<label for="phone" class="accountedit__label">
						{{ $t('account.edit.phone.label') }}
					</label>
					<div class="form__field__box">
						<select v-model="prefix" class="form__input" name="numero-prefix" id="side-widget-phone-prefix">
							<option value="+93">+93</option>
							<option value="+358">+358</option>
							<option value="+355">+355</option>
							<option value="+213">+213</option>
							<option value="+1684">+1684</option>
							<option value="+376">+376</option>
							<option value="+244">+244</option>
							<option value="+1264">+1264</option>
							<option value="+672">+672</option>
							<option value="+1268">+1268</option>
							<option value="+54">+54</option>
							<option value="+374">+374</option>
							<option value="+297">+297</option>
							<option value="+61">+61</option>
							<option value="+43">+43</option>
							<option value="+994">+994</option>
							<option value="+1242">+1242</option>
							<option value="+973">+973</option>
							<option value="+880">+880</option>
							<option value="+1246">+1246</option>
							<option value="+375">+375</option>
							<option value="+32">+32</option>
							<option value="+501">+501</option>
							<option value="+229">+229</option>
							<option value="+1441">+1441</option>
							<option value="+975">+975</option>
							<option value="+591">+591</option>
							<option value="+387">+387</option>
							<option value="+267">+267</option>
							<option value="+47">+47</option>
							<option value="+55">+55</option>
							<option value="+246">+246</option>
							<option value="+673">+673</option>
							<option value="+359">+359</option>
							<option value="+226">+226</option>
							<option value="+257">+257</option>
							<option value="+855">+855</option>
							<option value="+237">+237</option>
							<option value="+1">+1</option>
							<option value="+238">+238</option>
							<option value="+345">+345</option>
							<option value="+236">+236</option>
							<option value="+235">+235</option>
							<option value="+56">+56</option>
							<option value="+86">+86</option>
							<option value="+61">+61</option>
							<option value="+61">+61</option>
							<option value="+57">+57</option>
							<option value="+269">+269</option>
							<option value="+242">+242</option>
							<option value="+243">+243</option>
							<option value="+682">+682</option>
							<option value="+506">+506</option>
							<option value="+225">+225</option>
							<option value="+385">+385</option>
							<option value="+53">+53</option>
							<option value="+357">+357</option>
							<option value="+420">+420</option>
							<option value="+45">+45</option>
							<option value="+253">+253</option>
							<option value="+1767">+1767</option>
							<option value="+1849">+1849</option>
							<option value="+593">+593</option>
							<option value="+20">+20</option>
							<option value="+503">+503</option>
							<option value="+240">+240</option>
							<option value="+291">+291</option>
							<option value="+372">+372</option>
							<option value="+251">+251</option>
							<option value="+500">+500</option>
							<option value="+298">+298</option>
							<option value="+679">+679</option>
							<option value="+358">+358</option>
							<option value="+33">+33</option>
							<option value="+594">+594</option>
							<option value="+689">+689</option>
							<option value="+262">+262</option>
							<option value="+241">+241</option>
							<option value="+220">+220</option>
							<option value="+995">+995</option>
							<option value="+49">+49</option>
							<option value="+233">+233</option>
							<option value="+350">+350</option>
							<option value="+30">+30</option>
							<option value="+299">+299</option>
							<option value="+1473">+1473</option>
							<option value="+590">+590</option>
							<option value="+1671">+1671</option>
							<option value="+502">+502</option>
							<option value="+44">+44</option>
							<option value="+224">+224</option>
							<option value="+245">+245</option>
							<option value="+592">+592</option>
							<option value="+509">+509</option>
							<option value="+672">+672</option>
							<option value="+379">+379</option>
							<option value="+504">+504</option>
							<option value="+852">+852</option>
							<option value="+36">+36</option>
							<option value="+354">+354</option>
							<option value="+91">+91</option>
							<option value="+62">+62</option>
							<option value="+98">+98</option>
							<option value="+964">+964</option>
							<option value="+353">+353</option>
							<option value="+44">+44</option>
							<option value="+972">+972</option>
							<option value="+39" selected>+39</option>
							<option value="+1876">+1876</option>
							<option value="+81">+81</option>
							<option value="+44">+44</option>
							<option value="+962">+962</option>
							<option value="+7">+7</option>
							<option value="+254">+254</option>
							<option value="+686">+686</option>
							<option value="+850">+850</option>
							<option value="+82">+82</option>
							<option value="+383">+383</option>
							<option value="+965">+965</option>
							<option value="+996">+996</option>
							<option value="+856">+856</option>
							<option value="+371">+371</option>
							<option value="+961">+961</option>
							<option value="+266">+266</option>
							<option value="+231">+231</option>
							<option value="+218">+218</option>
							<option value="+423">+423</option>
							<option value="+370">+370</option>
							<option value="+352">+352</option>
							<option value="+853">+853</option>
							<option value="+389">+389</option>
							<option value="+261">+261</option>
							<option value="+265">+265</option>
							<option value="+60">+60</option>
							<option value="+960">+960</option>
							<option value="+223">+223</option>
							<option value="+356">+356</option>
							<option value="+692">+692</option>
							<option value="+596">+596</option>
							<option value="+222">+222</option>
							<option value="+230">+230</option>
							<option value="+262">+262</option>
							<option value="+52">+52</option>
							<option value="+691">+691</option>
							<option value="+373">+373</option>
							<option value="+377">+377</option>
							<option value="+976">+976</option>
							<option value="+382">+382</option>
							<option value="+1664">+1664</option>
							<option value="+212">+212</option>
							<option value="+258">+258</option>
							<option value="+95">+95</option>
							<option value="+264">+264</option>
							<option value="+674">+674</option>
							<option value="+977">+977</option>
							<option value="+31">+31</option>
							<option value="+599">+599</option>
							<option value="+687">+687</option>
							<option value="+64">+64</option>
							<option value="+505">+505</option>
							<option value="+227">+227</option>
							<option value="+234">+234</option>
							<option value="+683">+683</option>
							<option value="+672">+672</option>
							<option value="+1670">+1670</option>
							<option value="+47">+47</option>
							<option value="+968">+968</option>
							<option value="+92">+92</option>
							<option value="+680">+680</option>
							<option value="+970">+970</option>
							<option value="+507">+507</option>
							<option value="+675">+675</option>
							<option value="+595">+595</option>
							<option value="+51">+51</option>
							<option value="+63">+63</option>
							<option value="+64">+64</option>
							<option value="+48">+48</option>
							<option value="+351">+351</option>
							<option value="+1939">+1939</option>
							<option value="+974">+974</option>
							<option value="+40">+40</option>
							<option value="+7">+7</option>
							<option value="+250">+250</option>
							<option value="+262">+262</option>
							<option value="+590">+590</option>
							<option value="+290">+290</option>
							<option value="+1869">+1869</option>
							<option value="+1758">+1758</option>
							<option value="+590">+590</option>
							<option value="+508">+508</option>
							<option value="+1784">+1784</option>
							<option value="+685">+685</option>
							<option value="+378">+378</option>
							<option value="+239">+239</option>
							<option value="+966">+966</option>
							<option value="+221">+221</option>
							<option value="+381">+381</option>
							<option value="+248">+248</option>
							<option value="+232">+232</option>
							<option value="+65">+65</option>
							<option value="+421">+421</option>
							<option value="+386">+386</option>
							<option value="+677">+677</option>
							<option value="+252">+252</option>
							<option value="+27">+27</option>
							<option value="+211">+211</option>
							<option value="+500">+500</option>
							<option value="+34">+34</option>
							<option value="+94">+94</option>
							<option value="+249">+249</option>
							<option value="+597">+597</option>
							<option value="+47">+47</option>
							<option value="+268">+268</option>
							<option value="+46">+46</option>
							<option value="+41">+41</option>
							<option value="+963">+963</option>
							<option value="+886">+886</option>
							<option value="+992">+992</option>
							<option value="+255">+255</option>
							<option value="+66">+66</option>
							<option value="+670">+670</option>
							<option value="+228">+228</option>
							<option value="+690">+690</option>
							<option value="+676">+676</option>
							<option value="+1868">+1868</option>
							<option value="+216">+216</option>
							<option value="+90">+90</option>
							<option value="+993">+993</option>
							<option value="+1649">+1649</option>
							<option value="+688">+688</option>
							<option value="+256">+256</option>
							<option value="+380">+380</option>
							<option value="+971">+971</option>
							<option value="+44">+44</option>
							<option value="+1">+1</option>
							<option value="+598">+598</option>
							<option value="+998">+998</option>
							<option value="+678">+678</option>
							<option value="+58">+58</option>
							<option value="+84">+84</option>
							<option value="+1284">+1284</option>
							<option value="+1340">+1340</option>
							<option value="+681">+681</option>
							<option value="+967">+967</option>
							<option value="+260">+260</option>
							<option value="+263">+263</option>
						</select>
						<input type="number" name="phone" id="phone" class="form__input" :placeholder="$t('account.edit.phone.placeholder')" v-model="form.phone" />
					</div>
					<small v-if="errors && errors.phone" class="form__error" v-html="errors.phone"></small>
				</div>
				<div class="form__field dob">
					<label for="dob" class="accountedit__label">
						{{ $t('account.edit.dob.label') }}
					</label>
					<date-picker v-model="form.birthday" valueType="timestamp" format="DD/MM/YYYY" :disabled-date="(date) => date > new Date()">
						<template v-slot:icon-calendar>
							<svg viewBox="0 0 50 50" width="300" height="300">
								<use xlink:href="#icon--form-calendar" width="100%" height="100%"></use>
							</svg>
						</template>
					</date-picker>
					<small v-if="errors && errors.birthday" class="form__error" v-html="errors.birthday"></small>
				</div>
				<div class="form__field">
					<label for="fiscal_code" class="accountedit__label">
						{{ $t('account.edit.fiscal_code.label') }}
					</label>
					<input type="fiscal_code" name="fiscal_code" id="fiscal_code" class="form__input" :placeholder="$t('account.edit.fiscal_code.placeholder')" v-model="form.fiscal_code" maxlength="16" />
					<small v-if="errors && errors.fiscal_code" class="form__error" v-html="errors.fiscal_code"></small>
				</div>
				<div class="form__field">
					<label for="address" class="accountedit__label">
						{{ $t('account.edit.address.label') }}
					</label>
					<input type="address" name="address" id="address" class="form__input" :placeholder="$t('account.edit.address.placeholder')" v-model="form.address" />
					<small class="form__error"></small>
				</div>
				<div class="form__field half">
					<label for="city" class="accountedit__label">
						{{ $t('account.edit.city.label') }}
					</label>
					<input type="city" name="city" id="city" class="form__input" :placeholder="$t('account.edit.city.placeholder')" v-model="form.city" />
					<small class="form__error"></small>
				</div>
				<div class="form__field half">
					<label for="zip" class="accountedit__label">
						{{ $t('account.edit.zip.label') }}
					</label>
					<input type="zip" name="zip" id="zip" class="form__input" :placeholder="$t('account.edit.zip.placeholder')" v-model="form.zip" />
					<small class="form__error"></small>
				</div>
				<div class="form__field province">
					<label for="province" class="accountedit__label">
						{{ $t('account.edit.province.label') }}
					</label>
					<input type="province" name="province" id="province" class="form__input" :placeholder="$t('account.edit.province.placeholder')" v-model="form.province" />
					<small class="form__error"></small>
				</div>
				<div class="form__field actions">
					<span id="cancel" class="accountedit__button btn" @click="closeModal">
						{{ $t('common.btns.cancel') }}
					</span>
					<span id="save" class="accountedit__button btn filled" @click="saveEdit">
						{{ $t('common.btns.save') }}
					</span>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import DatePicker from 'vue2-datepicker';

import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Edit',
	props: {
		me: {
			type: Object,
			required: true,
			default: {}
		},
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		}
	},
	components: {
		DatePicker,
		Spinner
	},
	data() {
		return {
			form: {
				address: null,
				avatar: null,
				birthday: null,
				description: null,
				email: null,
				name: null,
				newsletter: null,
				phone: null,
				surname: null,
				fiscal_code: null,
				city: null,
				zip: null,
				province: null
			},
			prefix: '+39',
			isLoading: false,
			editSaved: false,
			errors: {
				birthday: null,
				email: null,
				name: null,
				phone: null,
				surname: null
			}
		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountedit', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.accountedit', {
					duration: 0.3,
					opacity: 1
				})
				.to('.accountedit__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.accountedit', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.accountedit__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.accountedit', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.accountedit', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.accountedit', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.accountedit', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		saveEdit() {
			this.isLoading = true;

			this.errors = null;
			this.msg = '';
			this.formResult = '';

			// check
			["name", "surname", "email", "fiscal_code", "phone", "birthday"].map(checkField => {
				if (this.form && !(this.form[checkField] && this.form[checkField].toString().trim().length)) {
					this.errors = (this.errors == null) ? {} : this.errors;
					// this.errors[checkField] = true;
					if(!this.errors[checkField] || this.errors[checkField] == '') {
						this.errors[checkField] = this.$t(`account.edit.errors.${checkField}`);
					}
				}
			})



			if (this.errors === null) {
				this.form.birthday = dayjs(this.form.birthday).format('YYYY-MM-DDTHH:mm:ss');

				console.log('PREFIX', this.prefix)
				this.form.phone = `${this.prefix}-${this.form.phone}`;
				this.$service.user.me.update(this.form).then(res => {
					this.editSaved = true;
					setTimeout(() => {
						this.isLoading = false;
						this.editSaved = false;
						if(this.mode == 'mobile') {
							this.closeDrawer()
						} else {
							this.closeModal();
						}
						EventBus.$emit('edit-saved');
					}, 3000);
				}).catch(err => {
					console.error('ERRs', err);
				})
			} else {
				// TODO: deve tornare su all'errore
				console.log('E', this.errors);
				this.formResult = 'error';
				this.msg = this.$t('form.formerror')
				this.isLoading = false;
				this.editSaved = false;
			}
		},
		init() {
			let me = this.me;
			if(me) {
				
				this.prefix = (me.phone && me.phone.split('-').length > 1) ? me.phone.split('-')[0] : '+39';
				
				this.form = {
					address: me.address ? me.address : null,
					avatar: me.avatar ? me.avatar : null,
					birthday: me.birthday ? dayjs(me.birthday).valueOf() : null,
					fiscal_code: me.fiscal_code ? me.fiscal_code.toUpperCase() : null,
					description: me.description ? me.description : null,
					email: me.email ? me.email : null,
					name: me.name ? me.name : null,
					newsletter: me.newsletter ? me.newsletter : null,
					phone: (me.phone && me.phone.split('-').length > 1) ? me.phone.split('-')[1] : me.phone,
					surname: me.surname ? me.surname : null
				}
			}
		}
	},
	mounted() {
		EventBus.$on('edit-account', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});
	}
}
</script>
<style lang="scss">
#fiscal_code {
	text-transform: uppercase;
}
.accountedit {
	--select: 100px;
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: auto;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0 var(--gutter);
		height: auto;
		overflow: auto;
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__close {
				@extend %icon;
				svg {
					@include size(70%,70%);
					fill: $color-black;
				}
			}
		}
		// &__title {
		// 	@extend %text-33;
		// 	display: block;
		// 	width: 100%;
		// 	font-family: $font-manrope;
		// 	font-variation-settings: 'wght' 700;
		// }
		&__text {
			@extend %text-19;
			display: block;
			width: 100%;
		}
		.accountform {
			@extend %flexStartBetween;
			flex-wrap: wrap;
			margin-top: var(--supergutter);
			display: block;
			.form__field {
				&__box {
					@extend %flexCenterBetween;
					width: 100%;
					select.form__input {
						width: 120px;
						margin-right: var(--thirdgutter);
					}
					input.form__input {
						width: calc(100% - (120 + var(--thirdgutter)));
					}
				}
				&.actions {
					@extend %flexCenterBetween;
					.accountedit__button {
						width: 49%;
					}
				}
				&.province {
					.form__select {
						width: 100%;
					}
				}
			}
			.form__error {
				@extend %text-10;
				display: block;
				width: 100%;
				text-transform: uppercase;
				color: $color-error;
				padding: 5px;
			}
		}
	}
	&__label {
		display: block;
		width: 100%;
		&__text {
			display: block;
			width: 100%;
		}
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		&.filled {
			background: $color-blue;
			color: $color-white;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue
		}
	}
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
		&__success {
			display: block;
			@extend %text-33;
			color: $color-green;
			font-variation-settings: 'wght' 700;
			text-align: center;
		}
	}
	.email {
		pointer-events: none;
		input {
			opacity: 0.41;
		}
	}
	.mx-datepicker {
		width: 100%;
		.mx-input {
			background: $color-white;
			height: var(--input);
			border: 1px solid $color-white;
			border-radius: var(--inputradius);
			padding: 0 var(--minigutter);
			border: 1px solid $color-grey;
		}
		.mx-icon-calendar {
			svg {
				fill: $color-black;
			}
		}
	}
	.phone {
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		.form {
			&__label {
				justify-content: space-between !important;
			}
			&__input {
				width: 100%;
			}
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 90vh;
			max-width: 780px;
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
			.accountform {
				display: flex;
			}
			.form__field {
				width: 48%;
				&.actions {
					margin-left: 52%;
					
				}
			}
		}
		&__button {
			margin-bottom: 0;
		}
	}
}
</style>
