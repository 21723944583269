<template>
	<div class="availabilitylist">
		<div class="availabilitylist__head">
			<span class="availabilitylist__head__back" @click="closeDrawer">
				<span class="availabilitylist__head__back__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="availabilitylist__head__back__label">
					{{ $t('common.back') }}
				</span>
			</span>
			<div class="availabilitylist__actions">
				<span class="availabilitylist__actions__btn btn" @click="newAvailability">
					<span class="availabilitylist__actions__btn__icon close">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--close" width="100%" height="100%"></use>
						</svg>
					</span>
					<span class="availabilitylist__actions__btn__label">
						{{  $t('availabilities.btns.new') }}
					</span>
				</span>
				<span class="availabilitylist__actions__btn btn" @click="editAvailability">
					<span class="availabilitylist__actions__btn__icon">
						<svg viewBox="0 0 50 50">
							<use xlink:href="#icon--edit" width="100%" height="100%"></use>
						</svg>
					</span>
					<span class="availabilitylist__actions__btn__label">
						{{  $t('availabilities.btns.edit') }}
					</span>
				</span>
			</div>
		</div>
		<div class="availabilitylist__box" :class="{ 'editmode': isEdit}">
			<template v-if="listLoading">
				<div class="availabilitylist__box__listloading">
					<Spinner />
				</div>
			</template>
			<template v-else>
				<template v-if="noavailabilities">
					<div class="availabilitylist__empty">
						<span class="availabilitylist__empty__close" @click="closeModal">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--close" width="100%" height="100%"></use>
							</svg>
						</span>
						<figure class="availabilitylist__empty__media">
							<img src="@/assets/images/noavailabilities.png" alt="" />
						</figure>
						<span class="availabilitylist__empty__title" v-html="$t('availabilities.empty.title')"></span>
						<span class="availabilitylist__empty__text" v-html="$t('availabilities.empty.text')"></span>
						<span class="availabilitylist__empty__add" @click="newAvailability"></span>
					</div>
				</template>
				<template v-else>
					<div class="availabilitylist__box__head" :class="{ 'disabled': isEdit }">
						<span class="availabilitylist__box__head__back" @click="closeModal">
							<span class="availabilitylist__head__back__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--back" width="100%" height="100%"></use>
								</svg>
							</span>
							<span class="availabilitylist__head__back__label">
								{{ $t('common.back') }}
							</span>
						</span>
						<div class="availabilitylist__actions">
							<span class="availabilitylist__actions__btn btn" @click="newAvailability">
								<span class="availabilitylist__actions__btn__icon close">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--close" width="100%" height="100%"></use>
									</svg>
								</span>
								<span class="availabilitylist__actions__btn__label">
									{{  $t('availabilities.btns.new') }}
								</span>
							</span>
							<span class="availabilitylist__actions__btn btn" @click="editAvailability">
								<span class="availabilitylist__actions__btn__icon">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--trash" width="100%" height="100%"></use>
									</svg>
								</span>
								<span class="availabilitylist__actions__btn__label">
									{{  $t('availabilities.btns.delete') }}
								</span>
							</span>
						</div>
					</div>
					<div class="availabilitylist__box__content">
						<span v-for="l in list" :key="l.month" class="avalist">
							<template v-if="l.sorted && l.sorted.length">
								<span class="avalist__month">
									{{ l.month }}
								</span>
								<span v-for="a in l.sorted" :key="a.id" class="avaitem" :class="{ 'disabled': a.booked }">
									<span v-if="!a.booked" class="avaitem__check">
										<input type="checkbox" :name="a.id" :id="a.id" :value="a" v-model="selected" />
										<span class="avaitem__check__icon">
											<svg viewBox="0 0 50 50">
												<use xlink:href="#icon--check" width="100%" heigth="100%"></use>
											</svg>
										</span>
									</span>
									<span class="avaitem__link" @click="editCurrentItem(a)">
										<span class="avaitem__link__info">
											<span class="avaitem__link__info__time start">
												{{ a.start }}
											</span>
											<span v-if="a.end" class="avaitem__link__info__time end">
												{{ a.end }}
											</span>
										</span>
										<span class="avaitem__link__price">
											<span class="avaitem__link__price__valiue">
												<template v-if="a.single_booking">
													&euro; {{ a.price }}
												</template>
												<template v-else>
													&euro; {{ a.price_hour }} {{ $t('availabilities.hour') }}
												</template>
											</span>
											<small v-if="a.single_booking" class="avaitem__link__price__txt">
												{{ $t('availabilities.info') }}
											</small>
											<small v-if="a.booked" class="avaitem__link__price__booked" v-html="$t('availabilities.booked')"></small>
										</span>
									</span>
								</span>
							</template>
						</span>
					</div>
					<div class="availabilitylist__editctas">
						<span class="availabilitylist__editctas__btn btn outline cancel" @click="closeEdit">
							{{ $t('availabilities.btns.cancel') }}
						</span>
						<span class="availabilitylist__editctas__btn btn filled delete" :class="{ 'disabled': !selected.length }" @click="deleteList">
							{{ $t('availabilities.btns.delete') }}
						</span>
					</div>
					<div class="availabilitylist__delete" :class="{ 'open': isDelete }">
						<DeleteAvailabilityList v-if="isDelete"  :selected="selected" />
					</div>
					<div v-if="isLoading" class="availabilitylist__loading">
						<template v-if="!editSaved">
							<span class="availabilitylist__loading__spinner">
								<span class="availabilitylist__loading__spinner__box">
									<Spinner />
								</span>
							</span>
						</template>
						<template v-else>
							<span class="availabilitylist__loading__success">
								{{ $t('common.edit_saved') }}
							</span>
						</template>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
gsap.registerPlugin(CustomEase);

import DatePicker from 'vue2-datepicker';

import Spinner from '@/views/components/common/Spinner';
import DeleteAvailabilityList from '@/views/components/garages/DeleteAvailabilityList';

export default {
	name: 'AvailabilityList',
	props: {
		mode: {
			type: String,
			required: true,
			default: 'mobile'
		},
		gid: {
			required: false
		}
	},
	components: {
		DatePicker,
		Spinner,
		DeleteAvailabilityList
	},
	data() {
		return {
			listLoading: true,
			noavailabilities: true,
			isLoading: false,
			editSaved: false,
			availabilities: null,
			isEdit: false,
			list: null,
			garageid: null,
			selected: [],
			isDelete: false,
		}
	},
	methods: {
		openModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.availabilitylist', {
						pointerEvents: 'all'
					})
				}
			})
				.to('.availabilitylist', {
					duration: 0.3,
					opacity: 1
				})
				.to('.availabilitylist__box', {
					duration: 0.3,
					y: '0%',
					opacity: 1
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set('.availabilitylist', {
						pointerEvents: 'none'
					})
				}
			})
			.to('.availabilitylist__box', {
				duration: 0.3,
				y: 150,
				opacity: 0
			})
			.to('.availabilitylist', {
				duration: 0.3,
				opacity: 0
			})
		},
		openDrawer() {
			gsap.to('.availabilitylist', {
				duration: 0.9,
				x: '0%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 "),
				onComplete: () => {
					gsap.set('.availabilitylist', {
						pointerEvents: 'all'
					})
				}
			})
		},
		closeDrawer() {
			gsap.to('.availabilitylist', {
				duration: 0.9,
				x: '100%',
				ease: CustomEase.create("custom", "M0,0 C0.726,0 0,1 1,1 ")
			})
		},
		closeEdit() {
			this.isEdit = false;
			this.selected = [];
		},
		deleteEdit() {
			EventBus.$emit('delete-availabilities', this.selected);
		},
		deleteList() {
			this.isDelete = true;
			// EventBus.$emit('delete-availabilities', this.selected);
		},
		editCurrentItem(item) {
			let s = dayjs(item.start_at);
			let e = dayjs(item.end_at);

			item.isedit = true;

			if(s.day() != e.day()) {
				EventBus.$emit('open-unique', item)
			} else {
				EventBus.$emit('open-single', item);
			}
			// EventBus.$emit('availability-form', { id: item.id, isedit: true });
			if(this.mode == 'mobile') {
				this.closeDrawer()
			} else {
				this.closeModal();
			}
		},
		newAvailability() {
			EventBus.$emit('availability-form', { isedit: false });
			this.isEdit = false;
			if(this.mode == 'mobile') {
				this.closeDrawer()
			} else {
				this.closeModal();
			}
		},
		editAvailability() {
			this.isEdit = true;
		},
		formatAvailabilities(a) {
			let today = new Date().getTime();

			// DATES
			let start = dayjs(a.start_at).valueOf();
			let end = dayjs(a.end_at).valueOf();

			if(dayjs(start).date() == dayjs(end).date()) {
				a.start = `${dayjs(start).format('DD MMM')} - ${dayjs(start).format('HH:mm')} - ${dayjs(end).format('HH:mm')}`;
			} else {
				a.start = `${dayjs(start).format('DD MMM - HH:mm')}`;
				a.end = `${dayjs(end).format('DD MMM - HH:mm')}`;
			}
			let month = dayjs(a.start_at).month();
			let year = dayjs(a.start_at).year();

			if(end >= today) {
				this.list[month].items.push(a);
			}
		},
		init() {
			this.selected = [];
			this.availabilities = [];
			this.isLoading = true;
			// let now = new Date().toISOString();
			let now = dayjs().format('YYYY-MM-DDTHH:mm:ss');
			
			this.$service.availability.get(this.gid, now).then(res => {
				this.isLoading = false;
				this.listLoading = false;
				if(res && res.data && res.data.length) {
					this.noavailabilities = false;
					this.availabilities = res.data;
					this.list = [];
					for(let i = 0; i < 12; i++) {
						this.list.push({
							"month": this.$t(`common.months[${i}]`),
							"items": []
						})
					}
					this.availabilities.forEach(a => {
						this.formatAvailabilities(a);
					})

					const sortArrayOfObjects = (arr) => {
						const sortedArr = arr.sort((a, b) => {
							if (new Date(a.start_at).getTime() < new Date(b.start_at).getTime()) {
								return -1;
							}
							if (new Date(a.start_at).getTime() > new Date(b.start_at).getTime()) {
								return 1;
							}
							return 0;
						});

						return sortedArr;
					};

					this.list.forEach(l => {
						if(l.items && l.items.length) {
							l.sorted = sortArrayOfObjects(l.items);
						}
					})

				} else {
					this.noavailabilities = true;
				}
			})
		}
	},
	mounted() {
		EventBus.$on('availability-modal', () => {
			this.init();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});

		EventBus.$on('ava-deleted', () => {
			this.init();
		})
		EventBus.$on('close-delete-list', () => {
			this.isDelete = false;
		})

		EventBus.$on('return-to-list', () => {
			this.init();
			this.$forceUpdate();
			if(this.mode == 'mobile') {
				this.openDrawer()
			} else {
				this.openModal();
			}
		});
	}
}
</script>
<style lang="scss">
.availabilitylist {
	--select: 100px;
	@extend %flexCenterCenter;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $color-seasalt;
	z-index: 10000;
	display: block;
	overflow: hidden;
	transform: translateX(100%);
	pointer-events: none;
	&__head {
		@extend %flexCenterStart;
		@include size(100%, var(--headDrawer));
		padding: 0 var(--gutter);
		&__back {
			@extend %flexCenterStart;
			width: calc(100% - var(--btn));
			cursor: pointer;
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-black;
				}
			}
			&__label {
				@extend %text-15;
				display: block;
				width: max-content;
				font-variation-settings: 'wght' 600;
				padding-left: var(--minigutter);
			}
		}
	}
	&__spinner {
		@extend %flexCenterCenter;
		width: 100%;
		margin: var(--gutter) 0;
	}
	&__box {
		@extend  %panel;
		background: transparent;
		padding: 0;
		height: calc(100svh - var(--headDrawer));
		overflow: hidden;
		&__listloading {
			@extend %flexCenterCenter;
			width: var(--supergutter);
			position: relative;
			padding: calc(var(--supergutter) * 3) 0;
			margin: 0 auto;
			.spinner {
				margin: 0 auto;
			}
		}
		&__head {
			@extend %flexCenterBetween;
			width: 100%;
			display: none;
			margin-bottom: var(--gutter);
			transition: 180ms linear;
			&__title {
				@extend %text-33;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 700;
			}
			&__back {
				@extend %flexCenterStart;
				width: calc(100% - var(--btn));
				cursor: pointer;
				&__icon {
					@extend %flexCenterCenter;
					@include size(var(--smallicon),var(--smallicon));
					svg {
						display: block;
						@include size(75%,75%);
						fill: $color-black;
					}
				}
				&__label {
					@extend %text-15;
					display: block;
					width: max-content;
					font-variation-settings: 'wght' 600;
					padding-left: var(--minigutter);
				}
			}
			&.disabled {
				opacity: 0.2;
				pointer-events: none;
			}
		}
		&__content {
			display: block;
			@include size(100%,calc(100% - var(--headDrawer) - var(--gutter)));
			overflow: auto;
			overflow-x: hidden;
		}
	}
	&__label {
		display: block;
		width: 100%;
		&__text {
			display: block;
			width: 100%;
		}
	}
	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		&.filled {
			background: $color-blue;
			color: $color-white;
		}
		&.outline {
			border-color: $color-blue;
			color: $color-blue
		}
	}
	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.86);
		@include size(100%,100%);
		pointer-events: all;
		z-index: 100;
		&__spinner {
			display: block;
			margin-bottom: var(--gutter);
			&__box {
				display: block;
				@include size(65px,65px);
				margin: 0 auto var(--gutter);
			}
			&__label {
				@extend %text-15;
				display: block;
				width: 100%;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				text-align: center;
			}
		}
		&__success {
			display: block;
			@extend %text-33;
			color: $color-green;
			font-variation-settings: 'wght' 700;
			text-align: center;
		}
	}
	&__empty {
		@extend %flexCenterCenter;
		flex-direction: column;
		@include size(330px,100%);
		margin: 0 auto;
		padding-top: var(--supergutter);
		position: relative;
		&__close {
			position: absolute;
			top: var(--thirdgutter);
			right: var(--thirdgutter);
			@extend %icon;
			svg {
				@include size(70%,70%);
				fill: $color-black;
			}
		}
		&__media {
			display: block;
			@include size(200px,220px);
			margin-bottom: var(--supergutter);
			&__image {
				display: block;
				@include size(100%,100%);
				object-fit: contain;
			}
		}
		&__title {
			@extend %text-25;
			font-family: $font-manrope;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--minigutter);
			text-align: center;
		}
		&__text {
			@extend %text-15;
			margin-bottom: var(--gutter);
			text-align: center;
		}
		&__add {
			@extend %btn;
			display: block;
			@include size(var(--addbtn),var(--addbtn));
			background: $color-white;
			border: 1px solid $color-blue;
			position: relative;
			padding: 0;
			box-shadow: $shadow;
			@extend %arrowClose;
			cursor: pointer;
			&:before,
			&:after {
				background: $color-blue;
				@include size(40%,3px);
			}
			&:before {
				transform: translate(-50%,-50%);
			}
			&:after {
				transform: translate(-50%,-50%) rotate(90deg);
			}
		}
	}
	.email {
		pointer-events: none;
		input {
			opacity: 0.41;
		}
	}
	.mx-datepicker {
		width: 100%;
		.mx-input {
			background: $color-white;
			height: var(--input);
			border: 1px solid $color-white;
			border-radius: var(--inputradius);
			padding: 0 var(--minigutter);
			border: 1px solid $color-grey;
		}
		.mx-icon-calendar {
			svg {
				fill: $color-black;
			}
		}
	}
	.phone {
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		width: 100%;
		.form {
			&__label {
				justify-content: space-between !important;
			}
			&__input {
				width: 100%;
			}
		}
	}
	&__actions {
		@extend %flexCenterEnd;
		width: 60%;
		&__btn {
			@extend %flexCenterCenter;
			border-radius: calc(var(--bigradius) / 2);
			border: 1px solid $color-silver;
			box-shadow: $shadow;
			padding: var(--thirdgutter) var(--minigutter);
			margin-left: var(--minigutter);
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--minigutter),var(--minigutter));
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-black;
				}
				&.close svg {
					transform: rotate(45deg);
				}
			}
			&__label {
				margin-left: var(--thirdgutter);
				display: none;
			}
		}
	}
	&__editctas {
		position: fixed;
		bottom: 0;
		left: 0;
		background: $color-white;
		@extend %flexCenterAround;
		width: 100%;
		padding: var(--gutter);
		border-top-left-radius: var(--bigradius);
		border-top-right-radius: var(--bigradius);
		box-shadow: 0 0 20px rgba($color-silver, 0.5);
		transform: translateY(100%);
		transition: 250ms linear;
		z-index: 10;
		&__btn {
			display: block;
			color: $color-black;
			font-variation-settings: 'wght' 700;
			padding: 0 var(--supergutter);
			cursor: pointer;
			&.delete {
				background: $color-red;
				color: $color-white;
				&.disabled {
					opacity: 0.2;
					pointer-events: none;
					filter: grayscale(1);
				}
			}
		}
	}
	&__delete {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		@include size(100%,100%);
		background: $color-white;
		transform: translateY(100%);
		transition: 250ms var(--animation-curve);
		z-index: 12;
		&.open {
			transform: translateY(0%);
		}
	}
	.avalist {
		display: block;
		width: 100%;
		margin-bottom: calc(var(--gutter) * 1.5);
		&__month {
			@extend %text-20;
			display: block;
			width: 100%;
			color: $color-blue;
			font-variation-settings: 'wght' 700;
			margin-bottom: var(--gutter);
			padding: 0 var(--gutter);
		}
	}
	.avaitem {
		display: block;
		width: 100%;
		padding: var(--thirdgutter);
		margin-bottom: var(--minigutter);
		position: relative;
		padding: 0 var(--gutter);
		cursor: pointer;
		
		&__check {
			position: absolute;
			top: 50%;
			left: calc(var(--gutter) - var(--supergutter));
			@extend %flexCenterCenter;
			@include size(var(--smallicon),var(--smallicon));
			z-index: 0;
			border: 2px solid $color-blue;
			border-radius: var(--inputradius);
			transform: translateY(-50%);
			transition: 250ms linear;
			overflow: hidden;
			input {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				@include size(100%,100%);
			}
			&__icon {
				@extend %flexCenterCenter;
				@include size(100%,100%);
				pointer-events: none;
				svg {
					display: block;
					@include size(60%,60%);
					fill: $color-white;
				}
			}
			input:checked ~ .avaitem__check__icon {
				background: $color-blue;
				svg {
					display: block;
				}
			}
			input:not(:checked) ~ .avaitem__check__icon {
				background: $color-white;
				svg {
					display: none;
				}
			}
		}
		&__link {
			@extend %panel;
			position: relative;
			@extend %flexCenterBetween;
			flex-wrap: wrap;
			width: 100%;
			font-family: $font-manrope;
			padding: var(--minigutter);
			z-index: 2;
			transition: 250ms linear;
			&__info {
				display: block;
				width: 65%;
				font-size: 0.9rem;
				font-variation-settings: 'wght' 600;
				&__time {
					@extend %flexCenterStart;
					width: 100%;
					line-height: 1;
					&:before {
						content: '';
						display: block;
						@include size(12px,12px);
						border: 2px solid $color-blue;
						margin-right: var(--minigutter);
						border-radius: 100%;
					}
					&.start {
						padding-bottom: 1px;
					}
					&.end {
						position: relative;
						padding-top: calc(var(--minigutter) + 1px);
						&:after {
							content: '';
							position: absolute;
							top: 0;
							left: 5px;
							display: block;
							background: $color-blue;
							@include size(2px,var(--minigutter));
						}
						
					}
				}
			}
			&__price {
				@extend %text-15;
				display: block;
				width: 35%;
				text-align: right;
				font-family: $font-manrope;
				font-variation-settings: 'wght' 600;
				&__txt {
					@extend %text-13;
					display: block;
					width: 100%;
					font-variation-settings: 'wght' 300;
				}
				&__booked {
					display: block;
					width: 100%;
					padding-left: var(--gutter);
					@extend %text-12;
					padding-top: var(--thirdgutter);
					text-transform: uppercase;
					color: $color-silver;
					font-variation-settings: 'wght' 700;
				}
			}
		}
		&.disabled {
			pointer-events: none;
			& * {
				pointer-events: none;
			}
		}
	}
	&__box {
		&.editmode {
			.avaitem {
				&__check {
					left: var(--gutter);
				}
				&__link {
					transform: translateX(var(--supergutter));
					pointer-events: none;
				}
			}
			.availabilitylist {
				&__editctas {
					transform: translateY(0%);
				}
			}
		}
	}
	@media (min-width: $from-large) {
		display: flex;
		background: rgba($color-black, 0.1);
		transform: translateX(0%);
		opacity: 0;
		&__head {
			display: none;
		}
		&__box {
			background: $color-white;
			max-height: 85vh;
			width: 780px;
			height: calc(100% - var(--headDrawer));
			padding: var(--gutter);
			transform: translateY(150px);
			opacity: 0;
			&__head {
				display: flex;
				margin-bottom: var(--thirdgutter);
			}
			&__text {
				font-size: 0.938rem;
			}
		}
		&__button {
			margin-bottom: 0;
		}
		&__actions {
			&__btn {
				&__label {
					display: block;
				}
			}
		}
		.avaitem {
			padding: var(--minigutter);
			border-bottom: 1px solid $color-antiflash;
			border-radius: var(--inputradius);
			&__check {
				left: calc(var(--minigutter) - var(--supergutter));
				transition: 250ms linear;
			}
			&__link {
				background: transparent;
				border-radius: 0;
				box-shadow: none;
			}
			&:hover {
				background: $color-antiflash;
			}
		}
		&__box {
			&.editmode {
				.avaitem {
					&__check {
						left: var(--minigutter);
					}
				}
			}
		}
	}
}
</style>
